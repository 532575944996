import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-hiring custom-page-grid custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "ef741459-b925-4a49-989c-c1797b32bc64",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className={""} style={{ padding: 16 }} textAlign={"center"}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1398}
                  businessType={"merchant"}
                  locationId={1469}
                  subheader={"Hiring Form"}
                  showLabels={true}
                  emailSubject={"Hiring Form"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Name",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "phone_number",
                      required: true,
                      inputType: "tel",
                      placeholder: "",
                      displayLabel: "Phone Number",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "email",
                      required: true,
                      inputType: "email",
                      placeholder: "example@gmail.com",
                      displayLabel: "Email",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "years_of_experience",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Years of Experience:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "current_salon_if_applicable",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Current Salon (if applicable):",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "multiSelect",
                      label: "do_you_have_a_valid_cosmetology_license",
                      options: [
                        { label: "Yes", value: "Yes" },
                        { label: "No", value: "No" },
                      ],
                      required: true,
                      inputType: "multiSelect",
                      placeholder: "",
                      displayLabel: "Do you have a valid cosmetology license?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "what_is_your_area_of_expertise_hair_nails_etc",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "What is your area of expertise (hair, nails, etc.)?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "are_you_proficient_in_any_specific_techniques_or_styles",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you proficient in any specific techniques or styles?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label:
                        "do_you_have_any_additional_certifications_or_training",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Do you have any additional certifications or training?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "are_you_available_to_work_full_time_or_part_time",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Are you available to work full-time or part-time?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "which_days_and_times_are_you_available_to_work",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Which days and times are you available to work?",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "please_provide_a_link_to_your_portfolio",
                      required: true,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Please provide a link to your portfolio",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label:
                        "please_provide_the_names_and_contact_information_of_two_professional_references",
                      required: true,
                      inputType: "textarea",
                      placeholder: "",
                      displayLabel:
                        "Please provide the names and contact information of two professional references.",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "what_sets_you_apart_from_other_stylists",
                      required: true,
                      inputType: "textarea",
                      placeholder: "",
                      displayLabel: "What sets you apart from other stylists?",
                      isFieldContactMapped: false,
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 23556 }) {
      title
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
